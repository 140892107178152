import React, { createContext } from 'react';
import {  WebChatOptions  }from './WebChatOptions';
// import { DirectLineOptions } from 'botframework-directlinejs';
import { DirectLineOptions, ServiceStatus, DirectLine } from 'botframework-directlinejs'; // not sure for now if resolutions in package.json will work

// import createStoreWithDevTools from 'botframework-webchat-core/lib/createStore';
import { DruidAction } from './hooks/useWebChatReducer';


export interface WebChatState {
    instanceID: string,
    initialized: boolean,
    loaded: boolean,
    minimized: boolean,
    newMessage: boolean,
    side: 'left' | 'right',
    containerElementVisible: boolean,
    contextMenuItems: string[],
    contextMenuTranslations: { [key: string]: string[] },
    directlineOptions: DirectLineOptions | null,
    directlineInstance: null | DirectLine ,
    speechOptions: Partial<{ speechToken: string, speechRegion: string, isVoiceChannel: boolean }>
    mergedChatOptions: WebChatOptions,
    pristineMergedChatOptions: Partial<WebChatOptions>, // chat options after all merges. does not contain runtime updates, like mergedchatoptions
    headerOptions: WebChatHeaderOptions,
    botLanguages: BotLanguage[],
    locale: string,
    showTyping: boolean,
    cardActionsDisabled: boolean,
    // isWindowFocused: boolean,
    isMobile: boolean,
    isVoiceInput: boolean, // different than authorizeResponse.isVoiceChannel. this is used in UI
    sensitiveDataInputMode: boolean,
    fileUploadInputMode: boolean, // not used -> calculated inside DruidSendBox, but we could change in the future if the desired behavior changes
    conversationHistoryShowAll: boolean,
    // store: ReturnType<typeof createStoreWithDevTools> | null, // must uncomment
    store: any,
    // bfWebchatProps: ComposerProps | null, // not used -> for the moment the props are obtained from root mergeChatOptions object
    serviceStatus: ServiceStatus,
    conversationToken: string | null, // this is internal
    INITIALIZATION_STATUS: 'uninitialized' | 'success' | 'failed',
    INITIALIZATION_STATUS_MESSAGE: string | null
}

export interface WebChatHeaderOptions {
   showHeader: boolean,
   showChatTitle: boolean,
   showCloseButton: boolean,
   showChangeSideButton: boolean,
   showLanguageSelector: boolean,
   showIcon: boolean,
   showAvatar: boolean,
   showVoiceInputSelector: boolean,
   showReload: boolean,
   showReset: boolean
}

export interface BotLanguage {
    name: string,
    nativeName: string, // could be renamed
    isDefault: boolean,
    isSelected?: boolean,
    icon: string,
    id: number,
    displayName?: string
 }

export type DruidWebchatContextType = [state: WebChatState, dispatch: React.Dispatch<DruidAction>, getState: () => WebChatState, druidStyleToEmotionObject: (style: any) => any];

const DruidWebchatContext = createContext<DruidWebchatContextType>(null!);
DruidWebchatContext.displayName = "DruidWebchatContext";
// const DruidWebchatContext = createContext<WebChatState>(null!);


export default DruidWebchatContext;