import Cookies from "js-cookie";
import { WebChatState } from "../WebChatContext";
import { DruidCookies } from "../WebChatOptions";

export function getCookie(name: string) {
    return Cookies.get(name);
}

export function setCookie(name: string, value: any, options: Cookies.CookieAttributes | null | undefined, druidWebChatState: WebChatState) {
    // console.info(`trying to set cookie ${name} with value ${value}`);
    const { mergedChatOptions: { druidCookieConsentEnabled, druidWebChatIsOpenedEnabled, conversationTokenEnabled, conversationToken_namePrefix, druidWebChatIsOpened_namePrefix, druidCookiesExpirationInDays }} = druidWebChatState;

    if(!options?.expires) {
        options = {...options, ...{ expires: druidCookiesExpirationInDays}};
    }
    if(name.indexOf(DruidCookies.DruidCookieConsent) > -1 && druidCookieConsentEnabled) {
        Cookies.set((conversationToken_namePrefix || "") + name, value, options as any);
        return;
    }
    if(!druidCookieConsentEnabled || getCookie(DruidCookies.DruidCookieConsent) === 'true') {
        if(name.indexOf(DruidCookies.DruidWebChatIsOpened) > -1) {
            if(!druidWebChatIsOpenedEnabled) {
                return;
            } else {
                return Cookies.set((druidWebChatIsOpened_namePrefix || "") + name, value, options as any);
            }
            
        }
        if(name.indexOf(DruidCookies.DruidConversationToken) > -1 ) {
            if(!conversationTokenEnabled) {
                return;
            } else {
                return Cookies.set((conversationToken_namePrefix || "") + name, value, options as any);
            }
        }

        if(name.indexOf(DruidCookies.DruidConversationLanguage) > -1 ) {
            if(!conversationTokenEnabled){
                return;
            } else {
                return Cookies.set((conversationToken_namePrefix || "") + name, value, options as any);
            }
        }

        Cookies.set(name, value, options as any);
    }
}

export function removeCookie(name: string, druidWebChatState: WebChatState) {
    const { mergedChatOptions: { conversationToken_namePrefix, druidWebChatIsOpened_namePrefix }} = druidWebChatState;

    if(name.indexOf(DruidCookies.DruidCookieConsent) > -1) {
        Cookies.remove((conversationToken_namePrefix || "") + name);
    }

    if(name.indexOf(DruidCookies.DruidConversationToken) > -1) {
        Cookies.remove((conversationToken_namePrefix || "") + name);
    }

    if(name.indexOf(DruidCookies.DruidConversationLanguage) > -1) {
        Cookies.remove((conversationToken_namePrefix || "") + name);
    }

    if(name.indexOf(DruidCookies.DruidWebChatIsOpened) > -1) {
        Cookies.remove((druidWebChatIsOpened_namePrefix || "") + name);
    }

}

export function removeAllCookies(druidWebChatState: WebChatState) {
    Object.keys(DruidCookies).forEach(cookie => {
        removeCookie(DruidCookies[cookie], druidWebChatState);
    });      
}