export const babelTransformObject = async (toTransform: any, withDecorate?: boolean): Promise<any> => {
    if (toTransform && typeof toTransform === "string") {
        const babel = await import(
          /* webpackChunkName: "babel_standalone" */
          '@babel/standalone'
        );
        try {
          const babelEval = eval(babel.transform((toTransform as string), { presets: ['es2015', 'react', 'stage-3'] }).code || "");
          if(withDecorate) {
            return  (...args) => {
                try {
                  return babelEval(...args);
                } catch (ex) {
                  console.error(`Failed to execute babel transformed object: with exception: ${ex}`);
                }
              };
          } else {
              return babelEval;
          }
         
        } catch (ex) {
          console.error(`Failed to babel transform object: with exception: ${ex}`);
          Promise.reject();
        }
    } else {
        return toTransform;
    }
    
}